/* .form{
    margin-right: 6vh;
    margin-left: 6vh;
}

.createQuestion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  background-color: rgb(221, 234, 231);
  padding: 40px;
  margin: 22px;
  flex: 40%;
  max-width: 22vw;
}
.inputText {
  width: 75%;
  background-color: white !important;
}
#contentTable {
  width: 100%;
  border-spacing: 24px;
  margin-top: 50px;
  margin-bottom: 75px;
  width: 100%;
  text-align: left;
}

.tableCathegory {
  background-color: #f2f2f2;
}

#contentTable th {
  padding-top: 16px;
  padding-bottom: 14x;
  padding-left: 20px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.leftAlignRow {
  padding-left: 20px;
}
.tableRow {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
}
input:disabled {
  color: black;
  border: none;
  background-color: #ffffff;
}

/*Create form*/
/* .inputRow{
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 24px;
}
.inputReciever{
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-right: 20px;
}   
.recieverBox{
    border: none;
    background-color: #ddd;
    margin: 1px;
    padding: 10px;
    font-size: 20px;
    width: 400px;
    height: 50px;
}

.selectedTableRow{
    background-color: #E3FDEB;
    padding-bottom: 5px;
}
.notSelectedTableRow{
    background-color: white;
}
.selectFormCathegory{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.selectFormCheck{
    height: 20px;
    width: 20px;
    margin-left: 35px;
}
.rowCathegoryTite{
    background: lightgrey;
}
.userAgreement{
    margin-top: 6vh;
    display: flex;
    flex-direction: column;
    max-width: 40vw;
    align-items: center;
    justify-content: center;
}
.centerWraper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
 */

.button {
  margin: 40px;
}

/*Page layout*/

/*Fonts*/

/* .pageTitle{
    padding-left: 24px;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
} */
/*List Forms*/


/*-----------------Textfields input (Weeklyplaning)-----------------*/
.utvecklaTextInput {
  margin-bottom: 40px;
}

/*-----------------Main containers on site-----------------*/
.pageContainer {
  padding: 30px 50px 30px 50px;
  max-width: 1280px;
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.mainContainer {
  display: flex;
  justify-content: center;
}
/*-----------------Tables used in FormList-----------------*/
.listTable {
  margin-bottom: 75px;
  width: 100%;
  text-align: left;
  border-spacing: 24px;
}
th {
  padding-bottom: 20px;
  font-size: large;
  font-weight: bold;
}
.cathegoryTitel {
  font-weight: bold;
}

/*-----------------Button (most styling found in component)-----------------*/
.buttonLink:link {
  text-decoration: none;
}
.roundButton:hover {
  background-color: white !important;
  color: #52685d !important;
  border-color: white !important;
}
/*-----------------H-tags-----------------*/
/* small screens */
@media only screen and (max-width: 600px) {
  h1 {
    /* padding-left: 24px; */
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  h2 {
    /* padding-left: 24px; */
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  h3 {
    /* padding-left: 24px; */
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  p {
    /* padding-left: 24px; */
    font-size: 14px;
  }
  .textField input {
    font-size: 14px;
  }
  .textField label {
    font-size: 14px;
  }
}
/* large Screens */
@media only screen and (min-width: 601px) {
  h1 {
    /* padding-left: 24px; */
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
  }
  h2 {
    /* padding-left: 24px; */
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  h3 {
    /* padding-left: 24px; */
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  p {
    /* padding-left: 24px; */
    font-size: 16px;
  }
  .textField input {
    font-size: 16px;
  }
  .textField label {
    font-size: 16px;
  }
}

/*-----------------Navigation Menu-----------------*/
.navigationMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #52685d;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 80px;
  max-height: 130px;
  width: 100%;
}
/* Nav dropdown menu */
.navMenuDropdown {
  position: absolute;
  top: 120px;
  background-color: #52685d;
  padding-bottom: 20px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 999999;
}
.mainMenu {
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;

  /* padding-top: 40px; */
}
.mainMenu:link {
  text-decoration: none;
}
.mainMenu:visited {
  text-decoration: none;
}
.mainMenu:hover {
  color: #f3f3f3;
}
.mainMenu:hover::after {
  content: "";
  position: absolute;
  left: 25%;
  right: 25%;
  bottom: 0;
  border: 1px solid black;
}
/*Används i WeeklyReflection för att visa upp sammanställningen av frågor och svar*/
/* .weeklyShowTitle{
    padding-bottom: 10px;
    font-size: large;
    font-weight: bold;
  }
  .h3{
    font-weight: 300;
    font-size: 24px;
  } */

.gridInListStyle {
  padding-bottom: 20px;
}


input[type="checkbox"] {
  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
}
